import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

let routes = [
	{
		path: '/',
		name: 'home',
		component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
	},
	{
		path: '/:channel(channel[1-9]\\d?)',
		name: 'homechannel',
		component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
		props: true,
		beforeEnter: (to, from, next) => {
			console.log(`访问的是 /${to.params.channel} 路径`)
			next()
		},
	},
	{
		path: '/privacy-policy',
		name: 'privacy',
		component: () => import('../pages/privacy.vue'),
	},
	{
		path: '/terms-of-service',
		name: 'terms',
		component: () => import('../pages/terms.vue'),
	},
	{
		path: '/:channel(channel[1-9]\\d?)/answer',
		name: 'answerchannel',
		component: () => import(/* webpackChunkName: "home" */ '../pages/answer.vue'),
		props: true,
		beforeEnter: (to, from, next) => {
			console.log(`访问的是 /${to.params.channel}/answer`)
			next()
		},
	},
	{
		path: '/answer',
		name: 'answer',
		component: () => import('../pages/answer.vue'),
	},
	{
		path: '/q&a',
		name: 'qa',
		component: () => import('../pages/qa.vue'),
	},
	{
		path: '/blogs',
		name: 'blogs',
		component: () => import('../pages/blogs.vue'),
	},
	{
		path: '/blogs/the-book-of-answers-transformative-success-stories',
		name: 'blog1',
		component: () => import('../pages/blog1.vue'),
	},
	{
		path: '/blogs/the-book-of-answers-a-beacon-of-wisdom-and-guidance-for-life-s-crucial-decisions',
		name: 'blog2',
		component: () => import('../pages/blog2.vue'),
	},
	{
		path: '/blogs/the-accuracy-of-the-book-of-answers-unveiling-the-power-of-this-mysterious-book',
		name: 'blog3',
		component: () => import('../pages/blog3.vue'),
	},
	{
		path: '/blogs/stress-management-guide-5-scientific-methods-to-help-you-relieve-stress',
		name: 'blog4',
		component: () => import('../pages/blog4.vue'),
	},
	{
		path: '/blogs/industry-insight-report-trends-and-insights-of-the-book-of-answers',
		name: 'blog5',
		component: () => import('../pages/blog5.vue'),
	},
	{
		path: '/blogs/the-book-of-answers-common-questions-and-unique-responses-and-why-people-are-drawn-to-divination-from-a-psychological-perspective',
		name: 'blog6',
		component: () => import('../pages/blog6.vue'),
	},
]

export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
